<script>
// const props = defineProps({
//     id: String,
//     label: String,
//     error: String,
//     required: Boolean,
// })

import { defineComponent } from 'vue';
import InputLabel from '../InputLabel.vue';
import InputError from '../InputError.vue';

export default defineComponent({
    name: 'BaseControl',
    components: {InputLabel, InputError},
    inheritAttrs: false,
    props: {
        id: String,
        label: String,
        error: String,
        required: Boolean,
        holderClass: String,
    }
});
</script>

<template>
    <div class="mb-5 relative" :class="holderClass">
        <InputLabel v-if="label" :for="id" :value="label" :required="required" />
        <slot />

        <InputError :message="error" class="mt-2" />
    </div>
</template>